<template>
  <v-layout row wrap>
    <v-flex xs12 md12>
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs3 md3>
                <v-select
                  v-model="cliente.tipo"
                  :items="tipos"
                  placeholder=" "
                  outline
                  label="Tipo de inscrição"
                  item-value="codigo"
                  item-text="descricao"
                  @change="changeTipo"
                  :disabled="finalizado"
                ></v-select>
              </v-flex>
              <v-flex xs3 md3 v-if="cliente.tipo === 'F'">
                <v-text-field
                  ref="cpf"
                  v-model="cliente.cnpj_cpf"
                  label="CPF"
                  outline
                  placeholder=" "
                  mask="###.###.###-##"
                  @blur="getCPF(cliente.cnpj_cpf)"
                  :disabled="finalizado"
                ></v-text-field>
              </v-flex>
              <v-flex xs3 md3 v-if="cliente.tipo === 'J'">
                <v-text-field
                  ref="cnpj"
                  v-model="cliente.cnpj_cpf"
                  label="CNPJ"
                  outline
                  placeholder=" "
                  mask="##.###.###/####-##"
                  @blur="getReceitaWS(cliente.cnpj_cpf)"
                  :disabled="finalizado"
                ></v-text-field>
              </v-flex>

              <v-flex xs6 md6></v-flex>

              <v-flex xs5 md5>
                <v-text-field
                  :rules="campoRules"
                  required
                  placeholder=" "
                  outline
                  label="Nome"
                  id="nome"
                  name="nome"
                  type="text"
                  v-model="cliente.nome"
                  @input="$event => cliente.nome = $event.toUpperCase()"
                  :disabled="finalizado"
                ></v-text-field>
              </v-flex>
              <v-flex xs4 md4>
                <v-text-field
                  :rules="emailRules"
                  placeholder=" "
                  outline
                  label="E-mail"
                  id="email"
                  name="email"
                  type="email"
                  required
                  v-model="cliente.email"
                  @input="$event => cliente.email = $event.toLowerCase()"
                  :disabled="finalizado"
                ></v-text-field>
              </v-flex>
              <v-flex xs3 md3>
                <v-text-field
                  :rules="campoRules"
                  required
                  v-model="cliente.telefone"
                  label="Telefone"
                  outline
                  placeholder=" "
                  mask="(##) ####-####"
                  :disabled="finalizado"
                ></v-text-field>
              </v-flex>

              <v-flex xs2 md2>
                <v-text-field
                  :rules="campoRules"
                  required
                  placeholder=" "
                  outline
                  label="Cep"
                  id="cep"
                  name="cep"
                  type="text"
                  mask="##.###-###"
                  v-model="cliente.cep"
                  @blur="getCep(cliente.cep)"
                  :disabled="finalizado"
                ></v-text-field>
              </v-flex>

              <v-flex xs4 md4>
                <v-text-field
                  :rules="campoRules"
                  required
                  placeholder=" "
                  outline
                  label="Bairro"
                  id="bairro"
                  name="bairro"
                  type="text"
                  v-model="cliente.bairro"
                  @input="$event => cliente.bairro = $event.toUpperCase()"
                  :disabled="finalizado"
                ></v-text-field>
              </v-flex>

              <v-flex xs4 md4>
                <v-text-field
                  :rules="campoRules"
                  required
                  v-model="cliente.logradouro"
                  placeholder=" "
                  outline
                  label="Logradouro"
                  id="logradouro"
                  name="logradouro"
                  type="text"
                  @input="$event => cliente.logradouro = $event.toUpperCase()"
                  :disabled="finalizado"
                ></v-text-field>
              </v-flex>

              <v-flex xs2 md2>
                <v-text-field
                  :rules="campoRules"
                  required
                  placeholder=" "
                  outline
                  label="Número"
                  id="numero"
                  name="numero"
                  type="text"
                  v-model="cliente.numero"
                  @input="$event => cliente.numero = $event.toUpperCase()"
                  :disabled="finalizado"
                ></v-text-field>
              </v-flex>

              <v-flex xs4 md4>
                <v-autocomplete
                  :rules="campoRules"
                  required
                  item-value="codigo"
                  item-text="nome"
                  v-model="cliente.estado_codigo"
                  :items="estados"
                  label="UF(Estado)"
                  placeholder=" "
                  outline
                  @change="changeState"
                  clearable
                  :return-object="false"
                  :disabled="finalizado"
                ></v-autocomplete>
              </v-flex>

              <v-flex xs4 md4>
                <v-autocomplete
                  :rules="campoRules"
                  required
                  :disabled="!cliente.estado_codigo || finalizado"
                  item-value="codigo"
                  item-text="nome"
                  v-model="cliente.cidade_codigo"
                  :items="cidades"
                  label="Cidade"
                  placeholder=" "
                  outline
                  clearable
                  :return-object="false"
                ></v-autocomplete>
              </v-flex>

              <v-flex xs4 md4>
                <v-text-field
                  placeholder=" "
                  outline
                  label="Complemento"
                  id="complemento"
                  name="complemento"
                  type="text"
                  v-model="cliente.complemento"
                  @input="$event => cliente.complemento = $event.toUpperCase()"
                  :disabled="finalizado"
                ></v-text-field>
              </v-flex>
              <v-toolbar flat class="transparent" v-if="!finalizado">
                <v-spacer></v-spacer>
                <v-btn color="green" class="white--text" @click="saveCliente(cliente)">Avançar</v-btn>
              </v-toolbar>
              <v-flex xs12 md12></v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
const utilModule = () => import('./../../services/util')
const estadoModule = () => import('./../../services/estado')
const cidadeModule = () => import('./../../services/cidade')
const receitaModule = () => import('./../../services/receitaws')
const clienteModule = () => import('./../../services/cliente')
const contratoModule = () => import('./../../services/contrato')

export default {
  name: 'ContratoCliente',
  props: ['finalizado'],
  data () {
    return {
      valid: true,
      estados: [],
      cidades: [],
      cliente: {
        codigo: null,
        nome: null,
        tipo: 'J',
        cnpj_cpf: null,
        email: null,
        telefone: null,
        cep: null,
        estado_codigo: null,
        cidade_codigo: null,
        logradouro: null,
        numero: null,
        bairro: null,
        complemento: null
      },
      emailRules: [
        v => !!v || 'E-mail é obrigatório.',
        v => /.+@.+/.test(v) || 'E-mail não é válido.'
      ],
      campoRules: [
        v => !!v || 'Campo é obrigatório.',
      ],
      tipos: [
        {
          codigo: 'J',
          descricao: 'CNPJ'
        },
        {
          codigo: 'F',
          descricao: 'Pessoa física'
        },

      ]
    }
  },
  mounted () {
    this.getAllEstados()
    this.getClienteByContrato()
  },
  methods: {
    async saveCliente (cliente) {
      // VALIDA FORMULARIO
      if (!this.$refs.form.validate()) {
        return
      }

      // VALIDA DOCUMENTO
      let utilService = await utilModule()
      let validDocumento = utilService.validaDocumento(cliente.cnpj_cpf)
      if (!validDocumento) {
        this.$swal('CPF/CNPJ inválido.', '', 'error')
        return
      }

      // ADICIONA OU ATUALIZA CADASTRO DE CLIENTE
      let clienteService = await clienteModule()
      if (cliente.codigo) {
        await clienteService.editCliente(this.$axios, cliente)
      } else {
        let respAddCliente = await clienteService.addCliente(this.$axios, cliente)
        cliente.codigo = respAddCliente.data.cliente_codigo
      }

      // VERIFICA SE JÁ TEM CONTRATO, CASO NÃO TENHA CRIA-SE
      if (!this.$route.params.venda) {
        let contratoService = await contratoModule()
        let resp = await contratoService.addContrato(this.$axios, cliente.codigo)
        if (resp.status === 200 && resp.data) {
          this.$router.push(`/vendas/detalhes/${resp.data.contrato_codigo}`)
        }
      }
      this.$eventHub.$emit('contratoDetalhes-goStep', 2)
    },
    async getClienteByDocumento (documento) {
      let clienteService = await clienteModule()
      let resp = await clienteService.getClienteByDocumento(this.$axios, documento)
      if (resp.status === 200 && resp.data) {
        if (resp.data.codigo) {
          this.cliente.codigo = resp.data.codigo
          this.cliente.nome = resp.data.nome
          this.cliente.numero = resp.data.numero
          this.cliente.bairro = resp.data.bairro
          this.cliente.complemento = resp.data.complemento
          this.cliente.tipo = resp.data.tipo
          this.cliente.cnpj_cpf = resp.data.cnpj_cpf
          this.cliente.email = resp.data.email
          this.cliente.telefone = resp.data.telefone
          this.cliente.cep = resp.data.cep
          this.cliente.estado_codigo = resp.data.estado_codigo
          this.cliente.cidade_codigo = resp.data.cidade_codigo
          this.cliente.logradouro = resp.data.logradouro
          await this.getAllCidades(this.cliente.estado_codigo)
        }
      }
    },
    changeTipo () {
      if (this.cliente.cnpj_cpf) {
        this.cliente.cnpj_cpf = null
      }
    },
    async getCep (cep) {
      let utilService = await utilModule()
      let resp = await utilService.getCep(cep)
      if (resp.status === 200 && resp.data) {
        this.cliente.logradouro = resp.data.logradouro.toUpperCase()
        this.cliente.complemento = resp.data.complemento.toUpperCase()
        this.cliente.bairro = resp.data.bairro.toUpperCase()
        let estado = this.estados.filter(estado => estado.sigla.toUpperCase() === resp.data.uf.toUpperCase())
        if (estado.length > 0) {
          this.cliente.estado_codigo = estado[0].codigo
          await this.getAllCidades(estado[0].codigo)
          let cidade = this.cidades.filter(c => c.nome.toUpperCase() === resp.data.localidade.toUpperCase())
          if (cidade.length > 0) {
            this.cliente.cidade_codigo = cidade[0].codigo
          }
        }
      }
    },
    async getAllEstados () {
      let estadoService = await estadoModule()
      let resp = await estadoService.getAll(this.$axios)
      if (resp.status === 200 && resp.data) {
        this.estados = resp.data
      }
    },
    async getAllCidades (estado_codigo) {
      let cidadeService = await cidadeModule()
      let resp = await cidadeService.getByEstado(this.$axios, estado_codigo)
      if (resp.status === 200 && resp.data) {
        this.cidades = resp.data
      }
    },
    async changeState (estado_codigo) {
      await this.getAllCidades(estado_codigo)
    },

    async getCPF (cpf) {
      let utilService = await utilModule()
      let cpfValid = await utilService.validaCPF(cpf)
      if (!cpfValid) {
        this.$swal('CPF inválido.', '', 'error')
        return
      }
      await this.getClienteByDocumento(cpf)
    },
    async getReceitaWS (cnpj) {
      let utilService = await utilModule()
      let cnpjValid = await utilService.validaCNPJ(cnpj)
      if (!cnpjValid) {
        this.$swal('CNPJ inválido.', '', 'error')
        return
      }
      await this.getClienteByDocumento(cnpj)
      if (!this.cliente.codigo) {
        let receitaService = await receitaModule()
        let resp = await receitaService.consultaReceita(cnpj)
        if (resp.status === 200 && resp.data && resp.data.nome) {
          this.cliente.nome = resp.data.nome
          this.cliente.cep = resp.data.cep.replace(/[^0-9]/g, '')
          this.cliente.bairro = resp.data.bairro
          this.cliente.logradouro = resp.data.logradouro
          this.cliente.numero = resp.data.numero
          this.cliente.complemento = resp.data.complemento
          this.cliente.email = resp.data.email
          this.cliente.telefone = resp.data.telefone
          await this.getCep(this.cliente.cep)
        }
      }
    },
    async getClienteByContrato () {
      if (this.$route.params.venda) {
        let clienteService = await clienteModule()
        let resp = await clienteService.getClienteByContrato(this.$axios, this.$route.params.venda)
        if (resp.status === 200 && resp.data) {
          if (resp.data.codigo) {
            this.cliente.codigo = resp.data.codigo
            this.cliente.nome = resp.data.nome
            this.cliente.numero = resp.data.numero
            this.cliente.bairro = resp.data.bairro
            this.cliente.complemento = resp.data.complemento
            this.cliente.tipo = resp.data.tipo
            this.cliente.cnpj_cpf = resp.data.cnpj_cpf
            this.cliente.email = resp.data.email
            this.cliente.telefone = resp.data.telefone
            this.cliente.cep = resp.data.cep
            this.cliente.estado_codigo = resp.data.estado_codigo
            this.cliente.cidade_codigo = resp.data.cidade_codigo
            this.cliente.logradouro = resp.data.logradouro
            await this.getAllCidades(this.cliente.estado_codigo)
          }
        }
      }

    }
  }
}

</script>